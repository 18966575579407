@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);
:root {
  --sistema: #034075;
  --sistema-antigo: #54B8E5;
  --background: #f0f2f5;
  --red: #e22e38;
  --red-light: #e8dbdd;
  --green: #33CC95;
  --red-dark: #dc969d;
  
  --relatorio-color: #0F703B;

  --text-title: #363F5F;
  --text-body: #676768;

  --shape: #FFFFFF;
  --info-color: #034075;
  --info-color-bkp: #2196f3;
  --info-light-color: #e9f5fe;

  --success-color: #4caf50;
  --success-light-color: #edfae1;

  --warning-color: #ffb300;
  --warning-light-color: #fff9e6;

  --error-color: #f44336;
  --error-light-color: #fde9ef;

  outline: unset;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
html{ 
  width: 100%;
  height: 100%;
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  outline: none;
}

body {
  width: 100%;
  min-height: 100vh;
  background-color: #EFF3FF !important;
}
@media (max-width: 1080px){
  body {
    font-size: 93.75%;
  }
}
@media (max-width: 720px){
  body {
    font-size: 87.5%;
  }
}
#root{
  background-color: #EFF3FF;
}
.rs-loader-backdrop{
  position: fixed !important;
  z-index: 9999;
}
.rs-modal-body{
  width: 100%;
}
.rs-modal-full{
  width: calc(100% - 10px)
}
.rs-modal-full.rs-modal-dialog{
  margin-left: 10px;
  margin-right: 10px;
}
.rs-modal-full.rs-modal{
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .rs-modal-sm {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 980px) {
  .modal_calculo {
    width: 100% !important;
  }
}

.rs-alert{
  z-index: 1200;
}
.rs-modal-wrapper{
  z-index: 1100;
}

.rs-dropdown-menu{
  padding: 5px 10px;
}
.rs-modal-body{
  min-height: 300px;
}
.rs-content{
  background: #EFF3FF;
}

.nav_sidebar > ul{
  display: flex;
  flex-direction: column;
}
.nav_sidebar > ul .rs-nav-item{
  transition: none;
}

.nav_sidebar > ul .rs-nav-item:hover,
.nav_sidebar > ul .rs-nav-item:focus,
.nav_sidebar > ul .rs-nav-item:hover a button i:before,
.nav_sidebar > ul .rs-nav-item a button:hover,
.nav_sidebar > ul .rs-nav-item a button:focus,
.nav_sidebar > ul .rs-nav-item a:hover,
.nav_sidebar > ul .rs-nav-item a:focus,
.nav_sidebar > ul .rs-nav-item.menu_ativo a button i:before{
  background: #333 !important;
  color: #e22e38 !important;
  color: var(--red) !important;
}
.nav_sidebar > ul .rs-nav-item a:hover,
.nav_sidebar > ul .rs-nav-item.menu_ativo a{
  border-left: 6px solid #e22e38 !important;
  border-left: 6px solid var(--red) !important;
}
.nav_sidebar > ul .rs-nav-item a button i:before{
  color: #FFF;
}

.nav_sidebar > ul .rs-nav-item:hover,
.nav_sidebar > ul .rs-nav-item:focus,
.nav_sidebar > ul .rs-nav-item.menu_ativo{
  background: #333 !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  color: #e22e38 !important;
  color: var(--red) !important;
}

.modal_body {
  padding-right: 20px;
}
.rs-pagination > li > a{
  color: #FFF;
}
.rs-nav-item.nav_item_modifier.rs-nav-item-active a {
  color: #e22e38 !important;
  color: var(--red) !important;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #999 !important;
}

.rs-modal.mostra{
  display: block;
}

.rs-modal.esconde{
  display: none !important;
}

.rs-modal-full .rs-modal-dialog{
  margin-left: 40px;
  margin-right: 40px;
}
.container_admin {
  height: calc(100vh - 70px); 
  overflow-y: scroll;
  margin-left: 228px;
  padding-bottom: 50px;
}
.container_admin::-webkit-scrollbar {
  width: 10px;
}
.container_admin::-webkit-scrollbar-thumb {
  background-color: #2196f3;
  border-radius: 10px;
}
.container_admin::-webkit-scrollbar-track{
  background-color: #FFF;
}
@media (max-width: 1300px) {
  .container_admin {
    width: 100%;
    margin-left: 0px;
    position: absolute;
    left: 0px;
    top: 90px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 1000px) {
  .imagem_modal img{
    width: 100%;
  }
}


.modal_dialog .MuiDialog-paperWidthSm{
  width: 100%;
  max-width: 600px;
}

#toastAgenda p{
  color: #333;
  margin-bottom: 10px;
}
/* topbar: #111*/

/* Calendário */

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  height: 64px;
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  height: 44px;
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #ddd;
}
.react-calendar__tile--now {
  background: #f0f0f0;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #f0f0f0;
}
.react-calendar__tile--hasActive {
  background: #ddd;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #ddd;
}
.react-calendar__tile--active {
  background: #ddd;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ddd;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #ddd;
}

button.btnPadrao{
  width: 100%;
  max-width: 252px;
  padding: 0 1.5rem;
  height: 4rem;
  background: #034075;
  background: var(--info-color);
  color: #FFFFFF;
  border-radius: 0.25rem;
  border: 0;
  font-size: 1rem;
  margin-top: 1.8rem;
  font-weight: 600;
  margin-left: 16px;
  cursor: pointer;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
}

.totalItens h1{
  font-weight: bold;  
}
